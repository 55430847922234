import React, {useEffect, useState } from 'react'
import { Link, useHistory} from 'react-router-dom';
import { useParams } from 'react-router'
import axios from "axios"

const SetOrderDetail = () =>{

    const [order, setOrder] = useState([])
    const history = useHistory()

    //const [ido, setIdo] = useState("")
    const [date, setDate] = useState("")
    const [name, setName] = useState("")
    const [lname, setLname] = useState("")
    const [tel, setTel] = useState("")
    const [tracking, setTracking] = useState("")
    const [facture, setFacture] = useState("")
    const [total, setTotal] = useState("")
    const [ep, setEp] = useState("")
    const [mp, setMp] = useState("")
    const [pmt, setPmt] = useState("")

    const { ido } = useParams()
    const urlAPI = `https://www.gomispro.com/api/orders/orderdetail.php?ido=${ido}`

    useEffect(() => {
        
        axios.get(urlAPI).then(res => { 
                                        setOrder(res.data)
                                        let p = res.data
                                        setName(p[0].firsname)
                                        setLname(p[0].lastName)
                                        //setIdo(p[0].ido)
                                        setDate(p[0].date)
                                        setTel(p[0].tel)
                                        setTracking(p[0].tracking)
                                        setFacture(p[0].facture)
                                        setTotal(p[0].total)
                                        setEp(p[0].ep)
                                        setMp(p[0].mp)
                                        setPmt(p[0].pmt)
                                        
        }).catch(err => {
            console.log(err)
            })
        }, [])

        console.log(order)

        const urlAPI_update_order_details = `https://www.gomispro.com/api/orders/updateod.php?
        ido=${ido}
        &n=${name}
        &l=${lname}
        &tel=${tel}
        &trck=${tracking}
        &f=${facture}
        &e=${ep}
        &m=${mp}
        &p=${pmt}`

    const updateDetails = () =>{
        console.log(urlAPI_update_order_details)
        axios.get(urlAPI_update_order_details).then((res) => {
            if(res.status == 200){
                alert("data update")
                history.push('/sales')
            }
          });
    }
    
  return (
    <div className='w-full h-auto px-12 flex flex-col py-4 items-center'>
        <div className='w-1/2 h-auto flex flex-col'>
            <div className='w-full h-16 flex flex-row items-center border rounded-md justify-between px-2'>
                    <span className='text-sm capitalize text-gray-800 mr-4'>Numéro de comande (sur site ) :</span>
                    <input type="text" 
                           className='w-72 h-10 bg-yellow-400 text-center rounded-md' value={ido}
                           disabled ></input>
            </div>

            <div className='w-full h-16 flex flex-row items-center border rounded-md justify-between px-2'>
                    <span className='text-sm capitalize text-gray-800 mr-4'>Date de Commande : <span className='text-blue-400'>{date} </span></span>
                    
            </div>

            <div className='w-full h-auto flex flex-row'>
                <div className='w-1/2 h-16 flex flex-row items-center border rounded-md justify-between px-2'>
                        <span className='text-sm capitalize text-gray-800 mr-4'>Non :</span>
                        <input type="text" className='w-72 h-10 border rounded-md text-center' value={name}
                                onChange={(e)=>setName(e.target.value)}></input>
                </div>
                <div className='w-1/2 h-16 flex flex-row items-center border rounded-md justify-between px-2'>
                        <span className='text-sm capitalize text-gray-800 mr-4'>Prénom :</span>
                        <input type="text" className='w-72 h-10 border rounded-md text-center' 
                        value={lname}
                        onChange={(e)=>setLname(e.target.value)}></input>
                </div>
            </div>

            <div className='w-full h-16 flex flex-row items-center border rounded-md justify-between px-2'>
                    <span className='text-sm capitalize text-gray-800 mr-4'>Mobile :</span>
                    <input type="text" className='w-72 h-10 border rounded-md text-center' 
                    value={tel}
                    onChange={(e)=>setTel(e.target.value)}></input>
            </div>

            <div className='w-full h-16 flex flex-row items-center border rounded-md justify-between px-2'>
                    <span className='text-sm capitalize text-gray-800 mr-4'>Numéro de Facture :</span>
                    <input type="text" className='w-72 h-10 border rounded-md text-center' 
                    value={facture}
                    onChange={(e)=>setFacture(e.target.value)}></input>
            </div>

            <div className='w-full h-16 flex flex-row items-center border rounded-md justify-between px-2'>
                    <span className='text-sm capitalize text-gray-800 mr-4'>Total de Facture :</span>
                    <input type="text" className='w-72 h-10 bg-yellow-400 rounded-md text-center' disabled 
                    value={total}
                    onChange={(e)=>setTotal(e.target.value)}></input>
            </div>

            <div className='w-full h-16 flex flex-row items-center border rounded-md justify-between px-2'>
                    <span className='text-sm capitalize text-gray-800 mr-4'>Tracking:</span>
                    <input type="text" className='w-72 h-10 border rounded-md text-center' 
                    value={tracking}
                    onChange={(e)=>setTracking(e.target.value)}></input>
            </div>

            
            <div className='w-full h-auto flex flex-col'>
                <div className='w-full h-16 flex flex-row items-center border rounded-md justify-between px-2'>
                        <span className='text-sm capitalize text-gray-800 mr-4'>etat de paiement :</span>
                        <select className='w-72 h-10 border rounded-md'
                                type="text"
                                onChange={(e)=>setEp(e.target.value)}
                                >
                                    <option disabled selected className='text-red-400'>selectionner une option</option>    
                                    <option >payée</option>
                                    <option >non payée</option>
                                    
                        </select>
                </div>
            </div>

            <div className='w-full h-auto flex flex-col'>
                <div className='w-full h-16 flex flex-row items-center border rounded-md justify-between px-2'>
                        <span className='text-sm capitalize text-gray-800 mr-4'>Mode de Payement :</span>
                        <select className='w-72 h-10 border rounded-md'
                                type="text"
                                onChange={(e)=>setMp(e.target.value)}
                                >
                                   <option disabled selected className='text-red-400'>selectionner une option</option>  
                                    <option >comptant</option>
                                    <option >en cours de livraison</option>
                                    <option >virement compte à compte ccp</option>
                                    <option >virement compte à compte bank</option>
                                    <option >Paiement par Cheque</option>
                        </select>
                </div>
            </div>

            <div className='w-full h-16 flex flex-row items-center border rounded-md justify-between px-2'>
                    <span className='text-sm font-bold capitalize text-red-500 mr-4'>reçu par / PMT / CCP / RIB :</span>
                    <input type="text" className='w-72 h-10 border rounded-md text-center' 
                    value={pmt}
                    onChange={(e)=>setPmt(e.target.value)}></input>
            </div>
            <div className='w-full h-16 flex flex-row items-center border rounded-md justify-end px-2'>
                    <button className='w-auto h-12 rounded bg-green-400 uppercase px-4 text-gray-800 text-sm'
                    onClick={()=>updateDetails()}>valider</button>
                    
            </div>
        </div>
    </div>
  )
}

export default SetOrderDetail