import React, {useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { FaFacebookSquare, FaFacebookMessenger, FaInstagramSquare,FaTwitterSquare } from 'react-icons/fa';
import axios from "axios"
    
 const AdminTP = () =>{

    const [order, setOrder] = useState([])
    const [dt, setDt] = useState([])
    const [total, setTotal] = useState([])
    const [items, setItems] = useState([])
    const [client, setClient] = useState([])
    //const [toClient, setToClient] = useState([])

    const { ido } = useParams()
    
    const urlAPI = `https://www.gomispro.com/api/orders/orderdetail.php?ido=${ido}`

    useEffect(() => {
        
        axios.get(urlAPI).then(res => { 
        setOrder(res.data)
        setDt(res.data[0].date)
        setTotal(res.data[0].total)
        setItems(JSON.parse(res.data[0].cartitems))
        setClient(JSON.parse(res.data[0].client))
        //setToClient(res.data[0].)
    }).catch(err => {
        console.log(err)
        })
    }, [])

    let newDate = new Date()
        let y = newDate.getFullYear()
        let m = newDate.getMonth() + 1
        let d = newDate.getUTCDate()
    return (
      
        <div  id="content">
        <div className="w-full h-auto flex flex-col relative"
            style={{backgroundColor:"#f5f5f5", width:"210mm", minHeight: '297mm', marginLeft: 'auto', marginRight: 'auto'}}>
            {/*INVOICE HEADER */}
            <div className="w-full h-24 bg-gray-700 flex flex-row items-center justify-center border-b-8 border-yellow-200">
                <div className="w-16 h-16 mr-4">
                    <img className="w-16 h-16" src={require('../../images/logo/logo.png').default}/>
                </div>
                <div className="w-auto h-16 flex flex-col">
                    <span className="uppercase text-xl font-bold text-white">gomis pro</span>
                    <span className="uppercase text-xs  text-white">
                       
                        boutique en ligne : produits esthétique automobile.</span>
                </div>
                
            </div>
            {/** INVOICE INFORMATION */}
            <div className="w-full h-32 flex flex-row">
                {/** CLIENT INFO */}
                <div className="w-1/3 h-full flex flex-col pl-4 py-2 ">
                    <span className="text-lg uppercase font-black font-sans text-gray-700 ">délivré à :</span>
                    <span className="text-md font-bold capitalize text-black">{client.firstname} - {client.lastName}</span>
                    <span className="text-sm text-black">{client.zip} - {client.adr} / {client.st}</span>
                    <span className="text-sm text-black">{client.mail}</span>
                    <span className="text-sm text-black text-red-500 font-bold">{client.tel}</span>
                </div>
                {/** invoice info */}
                <div className="w-1/3 h-full flex flex-col pl-4 py-2 ">
                    <span className="text-lg uppercase font-bold text-gray-700  font-sans">détails facture :</span>
                    <span className="text-md font-bold capitalize text-black">FA N° : {ido}/{m}{y}</span>
                    <span className="text-sm text-black">DATE : {dt}</span>
                    <span className="text-sm text-black">Le : {d}/{m}/{y}</span>
                </div>
                <div className="w-1/3 h-full flex items-center justify-center pl-4">
                    <span className="text-3xl font-bold text-yellow-400 uppercase bg-gray-700 w-full text-center pt-2 pb-2"> facture </span>
                </div>
            </div>
            <div className="w-full h-auto px-12 py-12"> 
                    <table className="w-full h-auto">
                        <thead className="w-full h-auto">
                            <tr className="w-full h-8 bg-yellow-400 ">
                                <td className="uppercase font-bold text-center text-sm">N°</td>
                                <td className="uppercase font-bold text-center text-sm">Article</td>
                                <td className="uppercase font-bold text-center text-sm">prix</td>
                                <td className="uppercase font-bold text-center text-sm">remise</td>
                                <td className="uppercase font-bold text-center text-sm">qtt</td>
                                <td className="uppercase font-bold text-center text-sm">sous total</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.keys(items).map((key, index) =>
                                    (
                                        <tr className="border-b border-gray-300">
                                            <td className="text-xs text-center py-2">{index + 1}</td>
                                            <td className="text-xs text-center py-2">{items[key].itemid} / {items[key].itemname}</td>
                                            <td className="text-xs text-center py-2">{items[key].itemprice}</td>
                                            <td className="text-xs text-center py-2">{items[key].itemremise}%</td>
                                            <td className="text-xs text-center py-2">{items[key].itemqtt}</td>
                                            <td className="text-xs text-center py-2">{(items[key].itemprice*((100 - items[key].itemremise)/100)) * items[key].itemqtt}</td>
                                        </tr>
                                    )
                                )
                            }
                            
                            
                        </tbody>
                    </table>
            </div>
            <div className="bg-gray-200 w-full h-auto flex flex-row ">
                <div className="w-1/2 h-full"></div>
                <div className="w-1/2 h-full flex flex-col pl-8">
                    <div className="w-full h-auto py-2 flex flex-row">
                        <span className="text-sm font-bold text-center capitalize mr-4 pl-2">sous total :</span>
                        <span className="text-sm  text-center capitalize">{parseFloat(total)}</span>
                    </div>
                    <div className="w-full h-auto py-2 flex flex-row">
                        <span className="text-sm font-bold text-center capitalize mr-4 pl-2">frais de livraison :</span>
                        <span className="text-sm  text-center capitalize">{total * 0.015}</span>
                    </div>
                    <div className="w-full h-auto py-2 flex flex-row bg-yellow-400">
                        <span className="text-sm font-bold text-center capitalize mr-4 pl-2">total :</span>
                        <span className="text-sm  text-center capitalize">{(parseFloat(total)+parseFloat((total)*0.015))}</span>
                    </div>
                </div>
            </div>
            {/** INVOICE FOOTER */}
            <div className="w-full h-auto bg-gray-700 flex flex-row border-t-8 border-yellow-200 absolute bottom-4">
                    <div className="w-1/4 h-auto flex flex-row items-center pl-4">
                        {/**icons */}
                        <div className="w-10 h-10">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-yellow-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                            </svg>
                        </div>
                        {/** details */}
                        <div className="w-auto h-auto px-2 py-2 flex flex-col ">
                            <span className="text-sm text-white">027 77 29 17</span>
                            <span className="text-sm text-white">05 59 09 68 35</span>
                            <span className="text-sm text-white">05 50 83 42 23</span>
                        </div>
                    </div>
                    <div className="w-1/4 h-20 flex flex-row items-center pl-4">
                        {/**icons */}
                        <div className="w-10 h-10">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-yellow-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                            </svg>
                        </div>
                        {/** details */}
                        <div className="w-auto h-auto px-2 py-2 flex flex-col">
                            <span className="text-sm text-red-500">contact@gomispro.com</span>
                            <span className="text-sm text-white">Chlef - Algeria</span>
                        </div>
                    </div>
                    <div className="w-1/4 h-20 flex flex-col justify-center items-center pl-4">
                       <div className="w-auto h-auto flex flex-col px-2 py-2">
                           <div className="w-auto h-auto flex flex-row justify-between">
                                 <FaFacebookSquare  className="text-yellow-400"/>
                                 <FaFacebookMessenger className="text-yellow-400"/>
                                 <FaInstagramSquare className="text-yellow-400"/> 
                                 <FaTwitterSquare className="text-yellow-400"/>
                           </div>
                           <span className="text-sm text-white">GOMIS PRO</span>
                       </div>
                       
                    </div>
                    <div className="w-1/4 h-20 flex flex-row items-center pl-4">
                    
                        {/** barcode */}
                        <div className="w-auto h-auto px-2 py-2 flex flex-col">
                        </div>
                    </div>
            </div>
        </div>
    </div>
    )
}

export default AdminTP
