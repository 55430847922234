import React, {useEffect, useState }  from 'react'
import Slider from 'react-slick'
import { Link } from 'react-router-dom';
import axios from "axios"
 

const Brands = () =>{
    
    const [b, setB] = useState([])
    const urlAPI = `https://www.gomispro.com/api/brand/allbrand.php`

    useEffect(() => { 
        axios.get(urlAPI).then(res => { 
        setB(res.data)
        
    }).catch(err => {
        console.log(err)
        })
    }, [])

    
    const settings = {
        infinite:true,
        autoplay: true,
        slidesToShow: 7,
        slidesToScroll: 1,
        swipeToSlide: true,
        cssEase: "linear",
        
        responsive: [
            {
              breakpoint: 320,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite:true
              }
            },
            {
                breakpoint: 720,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite:true
                }
              }
            
          ]
    }
    

    return (
        <div className="w-full h-auto flex flex-col justify-center lg:px-12 lg:mb-12 xs:px-4 bg-gray-800">
                {/*
                <div className="w-full h-auto pl-2 mb-2">
                    <span className="text-md font-semibold py-2 border-b-2 border-yellow-400 pr-2">NOS MARQUES</span> 
                </div>
                ../images/brands_logo/${br.brand}.jpg
                */
                <Slider {...settings} className="w-auto h-auto py-2 ">
                    {
                        b.map((br, index)=>(
                            <Link to={`brand/${br.brand}`}  className="mr-2" > 
                            
                                <div className="flex flex-row border px-2 py-2  border-gray-100 rounded-full  items-center hover:border-yellow-300 hover:bg-yellow-400">
                                    <img className="lg:w-10 lg:h-10 rounded-full lg:mr-4 xs:w-4 xs:h-4" src={`https://www.gomispro.com/api/brand/logo/${br.logo}`} />
                                    <span className="truncate text-sm font-bold text-white capitalize">{br.brand}</span>
                                </div>
                                
                            </Link>
                        ))
                    }
                </Slider>
                }
              
       </div>
    )
}

export default Brands
