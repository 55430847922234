import React from 'react'
import AdminNav from '../../components/admin/AdminNav'
import StatByClient from '../../components/admin/StatByClient'

const clientStt = () =>{
  return (
    <div>
        <AdminNav />
        <StatByClient />
    </div>
    
    
  )
}

export default clientStt