import React from 'react'
import AdminNav from '../../components/admin/AdminNav'
import ClientOrder from '../../components/admin/ClientOrder'
import { useParams } from 'react-router'
import OrderId from '../../components/admin/OrderId'



const thisOrder = () =>{
    
    return (  
        <div>
            <AdminNav />
            <ClientOrder /> 
        </div>
    )
}

export default thisOrder
