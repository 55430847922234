import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router'
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import axios from 'axios';

const TicketLb = () =>{

    const [p, setP] = useState([])
    const { id } = useParams(); 
    const urlAPI = `https://www.gomispro.com/api/product/product.php?idv=${id}`

    useEffect(() => {
        axios.get(urlAPI).then(res => { 
        setP(res.data)
        
    }).catch(err => {
        console.log(err)
        })
    }, [])


    
    const downimg = ()=>{
        htmlToImage.toJpeg(document.getElementById('my-node'), { quality: 0.95 })
         .then(function (dataUrl) {
           var link = document.createElement('a');
           link.download = 'my-image-name.jpeg';
           link.href = dataUrl;
           link.click();
         });
     }

    const LabelStyle = {
         width:'163',
        height: '124'
      }

  return (
    <div className='w-full h-auto flex flex-col pt-8 justify-center'>
                <div className='labelsize flex justify-center items-center mb-2 text-sm' style={LabelStyle} id="my-node">
                    <div className='tbx flex justify-center items-center flex-col'>
                   
                    {
                            p.length && p.map((product,index)=>
                            <div>
                                    <div className='w-full text-center'>منتوج خاص بالمحترفين</div>
                                        <div className='w-full text-center flex flex-col justify-center items-center'>
                                         
                                          <span>{product.brand }/ {product.produit}</span>
                                          <span>المستورد : ش ذ ذ م قوميس</span>
                                          <span >العنوان: حي بن سونة رقم 63 الشلف 02000</span>
                                          <span >الهاتف :027772917/0559096835</span>
                                        </div>


                            </div>
                            
                                
                                    )
                                    }
                     </div>     
               
                 </div>

          <div className='w-full h-auto flex items-center justify-center'>
                   
                <button  type="button"
                  className='w-1/2 h-auto px-2 py-2 bg-yellow-400 flex flex-row justify-center rounded-md'
                  onClick={()=>downimg()}>

                        <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" 
                                className="w-6 h-6 mr-2">
                              <path strokeLinecap="round" strokeLinejoin="round" d="m9 13.5 3 3m0 0 3-3m-3 3v-6m1.06-4.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
                        </svg>
                        <p>
                          Télecharger label
                        </p>
                          
                     
                  </button>
              </div>

    </div>
  )
}

export default TicketLb