import React, {useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios"
import { increment } from '../redux/cartCounter'
import { addtoamount } from '../redux/totalInCart'
import quantity, { incrementQtt, decrementQtt } from '../redux/quantity'
import SameProduct from './SameProduct';
import MainItem from './MainItem';
import SameCategorie from './SameCategorie';
import ParticleAnimation from 'react-particle-animation'



const Details = () =>{
     
    //const refreshPage = ()=>{
        //window.location.reload();
     //}
    
    const [p, setP] = useState([])
    //const [c, setC] = useState("")
    //const [b, setB] = useState("")
    const { id } = useParams(); 
 
    //setUrlId(id)
         const urlAPI = `https://www.gomispro.com/api/product/product.php?idv=${id}`

        useEffect(() => {
            axios.get(urlAPI).then(res => { 
            setP(res.data)
            
        }).catch(err => {
            console.log(err)
            })
        }, [])

    

    return (
        <div className="flex flex-col mt-36 h-auto">     
            
       {
           
           p.length && p.map((item, index)=>
                
                
                <div>
                    <div className="w-full h-auto flex flex-row lg:pl-12 lg:pr-12">
                        {/* left side */}
                            <div className="lg:w-1/4 lg:h-full xs:hidden lg:block ">
                               
                            </div>
                        {/*  right side*/}
                        <MainItem productid={id} />    
                                      
                    </div>   
                    
                    <SameCategorie categorie={item.categorie} />
                    {/**<SameProduct brand={item.brand} /> */}             
                </div>
           )
       }
       
        </div>                            
        )
}

export default Details