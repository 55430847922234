import React, { useState, useEffect } from 'react'

import axios from 'axios'
import { Bar } from 'react-chartjs-2'
const MostVisited = () =>{
    const [product, setProduct] = useState([])
    const [productlabel, setProductLabel] = useState([])
    const [productdata, setProductData] = useState([])
    let pl_array = [] //product_labbel_array
    let pd_array = [] //product_datasets_array
    const urlAPIVISITE  = `https://www.gomispro.com/api/product/mostvisited.php`
    useEffect(() => {
        axios.get(urlAPIVISITE).then(res => { 
            let r = res.data
            let i = 0
            r.forEach(element => {
                pl_array[i] = element.produit     
                pd_array[i] = element.visited
                i++
            });    
            setProductLabel(pl_array) 
            setProductData(pd_array)
          }).catch(err => {
            console.log(err)
          })
    }, [])

    
    return (
        <div className="w-full h-auto flex flex-col px-12 py-4">
            <div className="w-fill h-auto px-2 py-4">
                <span className="text-sm capitalize font-bold">produits les plus visités :</span>
            </div>

            <div className="w-full h-auto">
                
                <Bar
                    data={{
                        labels:productlabel,
                        width:50,
                        datasets:[{
                            label:"# de visite",
                            data:productdata,
                        }]
                    }}
                    width={100}
                    height={500}
                    options={{
                        maintainAspectRatio: false,
                    }}
                />
            </div>
            {/** */}
        </div>
    )
}

export default MostVisited 
