import React, {useEffect, useState} from 'react'

const Addcat = () =>{
    const [nb, setNb] = useState("")
    const [img, setImg] = useState("")

    const setIm = (e) =>{
        
        let files = e.target.files
        setImg(files)
        
    }

    let image_array = []
    for (let index = 0; index < img.length; index++) {
        const element = img[index];
        image_array[index] = element['name']

    }

    const urlApi = `https://www.gomispro.com/api/categorie/newcat.php?
                                                                        c=${nb}
                                                                        &logo=${image_array[0]}`

     const newBrand = () =>{

        let form = document.getElementById("mform")
        form.submit();
        console.log(urlApi)
    }                     

    return (
        <div className="w-full h-screen flex flex-col justify-center items-center bg-gray-100">
        <div className="w-1/2 h-1/2 bg-white px-4 py-4 border rounded-lg flex flex-col items-center justify-center">
                <div className="w-full flex flex-col space-y-2 px-2">
                    <span className="text-sm font-semibold pl-2 capitalize">nom de categorie :</span>
                    <input type="text" className="w-full h-10  border pl-8 focus:border-yellow-400"
                        value={nb}
                        onChange={(e)=>setNb(e.target.value)}
                    ></input>
                </div>

                <div className="w-full flex flex-col space-y-2 px-2">
                    
                    <span className="text-sm font-semibold pl-2 capitalize">image :</span>
                    <form method="post" encType="multipart/form-data" action={urlApi} id="mform" target="votar">
                        <input type="file" 
                            name="image[]"
                            className="w-full h-10 py-2 border pl-8 focus:border-yellow-400"
                            multiple
                            //value={img.length}
                            onChange={(e)=>setIm(e)}
                        ></input>
               


                    </form>
                    
                </div>

                <div className="w-full h-auto flex flex-row justify-end px-12 py-4">
                    <button type="submit" className="w-auto h-auto px-4 py-2 rounded-full bg-yellow-400 text-gray-700 capitalize text-sm font-bold"
                    onClick={()=>newBrand()}
                    >ajouter</button>
                </div>
                <iframe name="votar" className="none"></iframe>
        </div>
</div>
    )
}

export default Addcat
