import React, {useEffect, useState } from 'react'
import { useParams } from 'react-router'
import axios from "axios"
const Publication = () =>{
    const [p, setP] = useState([])
    const { id } = useParams(); 

    const urlAPI = `https://www.gomispro.com/api/product/product.php?idv=${id}`

    useEffect(() => {
        axios.get(urlAPI).then(res => { 
        setP(res.data)
        
    }).catch(err => {
        console.log(err)
        })
    }, [])

    console.log(p)
  return (
    <div className='w-full h-screen px-8 py-8 flex flex-row'>
           {
            p.length && p.map((product,index)=>
            
                <div className='w-full h-full flex flex-row border border-yellow-400'>
                    <div className='w-1/2 h-full bg-contain' 
                    style={{backgroundImage:`url(https://www.gomispro.com/api/product/images/${product.thumb})`}}>

                        
                    </div>
                
                    {/**details */}
                    <div className='w-1/2 h-full bg-yellow-400 flex flex-col justify-center items-center'>
                        <span className='uppercase text-7xl font-black text-gray-900 my-4'>promotion</span>
                        <div className='w-auto h-auto px-4 flex flex-row'>
                            <span className='text-gray-800 text-3xl uppercase font-bold'>{product.brand} - {product.produit}</span>
                        </div>
                        <div className='w-auto h-auto bg-red-400 px-4 py-2 '>
                          <span className='text-white font-black text-3xl my-8 line-through'>{product.price} DA</span>
                        </div>
                         <span className='text-red-600 font-black text-7xl my-8'>{product.price * ((100 - product.remise)/100)} DA</span>
                        
                        
                        <div className='w-full h-auto flex flex-col justify-center items-center'>
                             <div className='flex flex-row items-center'>
                                <span className='uppercase text-md text-gray-800 font-bold '>commander sur le site : <span className='text-md text-gray-800 font-bold'>www.gomispro.com</span> </span>
                                
                            </div>
                            <div className='h-auto flex flex-row items-center'>
                            
                                <span className='uppercase text-md text-gray-800 font-bold '>contactez nous :</span>
                                <span className='text-gray-800 font-bold'> 027 77 29 17  | 05 50 83 42 23 | 05 59 09 68 35</span>
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            
            )
           }
    </div>
  )
}

export default Publication