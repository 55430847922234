import React, {useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { FaFacebookSquare, FaFacebookMessenger, FaInstagramSquare,FaTwitterSquare } from 'react-icons/fa';
import axios from "axios"

const AdminCP = () =>{
    const [order, setOrder] = useState([])
    const [dt, setDt] = useState([])
    const [total, setTotal] = useState([])

    const [items, setItems] = useState([])
    const [client, setClient] = useState([])
    //const [toClient, setToClient] = useState([])
    //console.log(order)
    const { ido } = useParams()
    const urlAPI = `https://www.gomispro.com/api/orders/orderdetail.php?ido=${ido}`

    useEffect(() => {
        
        axios.get(urlAPI).then(res => { 
        setOrder(res.data)
        let d = res.data[0].cartitems
        setDt(res.data[0].date)
        setTotal(res.data[0].total)
        localStorage.setItem('clientpanier', d)
        localStorage.setItem('orderId', ido)
        localStorage.setItem('client',res.data[0].client)
        //console.log(typeof(d))
        //console.log(d)
        
        //setItems(JSON.parse(res.data[0].cartitems))
        setItems(JSON.parse(localStorage.getItem("clientpanier")))
        
        setClient(JSON.parse(res.data[0].client))
        //setToClient(res.data[0].)
    }).catch(err => {
        console.log(err)
        })
    }, [])
    
    console.log(order)
    const refreshPage = ()=>{
        window.location.reload();
     }

    //console.log(order.length)
   const setRemise = (index,id, r) =>{
        let panier = JSON.parse(localStorage.getItem('clientpanier'))
        panier[id].itemremise = r
        localStorage.setItem('clientpanier', JSON.stringify(panier))
   }

   const setqtt = (index,id, r) =>{
        let panier = JSON.parse(localStorage.getItem('clientpanier'))
        panier[id].itemqtt = r
        localStorage.setItem('clientpanier', JSON.stringify(panier))
    }

    const removeProduct = (id) =>{
        //let panier = JSON.parse(localStorage.getItem("clientpanier"))
        //console.log(panier)
        //delete panier[id] 
        let panier = items
        delete panier[id]
        setItems(panier)
        //delete panier[id]
        //localStorage.setItem('clientpanier', JSON.stringify(panier))
        //refreshPage()
        //alert("product removed."+id)
    }


    let newDate = new Date()
        let y = newDate.getFullYear()
        let m = newDate.getMonth() + 1
        let d = newDate.getUTCDate()

    return (
        
        <div>
        <div className="w-full h-auto flex flex-col relative"
            style={{backgroundColor:"#f5f5f5", width:"210mm", minHeight: '297mm', marginLeft: 'auto', marginRight: 'auto'}}>
            {/*INVOICE HEADER */}
            <div className="w-full h-24 bg-gray-700 flex flex-row items-center justify-center border-b-8 border-yellow-200">
                <div className="w-16 h-16 mr-4">
                    <img className="w-16 h-16" src={require('../../images/logo/logo.png').default}/>
                </div>
                <div className="w-auto h-16 flex flex-col">
                    <span className="uppercase text-xl font-bold text-white">gomis pro</span>
                    <span className="uppercase text-xs  text-white">
                       
                        boutique en ligne : produits esthétique automobile.</span>
                </div>
                
            </div>
            {/** INVOICE INFORMATION */}
            <div className="w-full h-32 flex flex-row">
                {/** CLIENT INFO */}
                <div className="w-1/3 h-full flex flex-col pl-4 py-2 ">
                    <span className="text-lg uppercase font-black font-sans text-gray-700 underline">délivré à :</span>
                    <span className="text-md font-bold capitalize text-black">{client.firstname} - {client.lastName}</span>
                    <span className="text-sm text-black">{client.zip} - {client.adr} / {client.st}</span>
                    <span className="text-sm text-black">{client.mail}</span>
                    <span className="text-sm text-black text-red-500 font-bold">{client.tel}</span>
                </div>
                <div className="w-1/3 h-full flex flex-col  justify-center pl-4">
                             <span className="text-sm text-black">contact@gomispro.com</span>
                            <span className="text-sm text-black">Chlef - Algeria</span>
                            <span className="text-sm text-black">027 77 29 17</span>
                            <span className="text-sm text-black">05 59 09 68 35</span>
                            <span className="text-sm text-black">05 50 83 42 23</span>
                       
                </div>
                {/** invoice info */}
                <div className="w-1/3 h-full flex flex-col pl-4 py-2 ">
                    <span className="text-xl font-bold text-yellow-400 uppercase bg-gray-700 w-full text-center py-2"> bon de livraison </span>
                    <span className="text-md font-bold capitalize text-black">FA N° : {ido}/{m}{y}</span>
                    <span className="text-sm text-black">DATE : {dt}</span>
                    <span className="text-sm text-black">Le : {d}/{m}/{y}</span>
                </div>
                
            </div>
            <div className="w-full h-auto px-12 py-12"> 
                    <table className="w-full h-auto">
                        <thead className="w-full h-auto">
                            <tr className="w-full h-8 bg-yellow-400 ">
                                <td className="uppercase font-bold text-center">N°</td>
                                <td className="uppercase font-bold text-center">Article</td>
                                <td className="uppercase font-bold text-center">prix</td>
                                <td className="uppercase font-bold text-center">remise</td>
                                <td className="uppercase font-bold text-center">qtt</td>
                                <td className="uppercase font-bold text-center">sous total</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.keys(items).map((key, index) =>
                                    (
                                        <tr className="border-b border-gray-300">
                                            <td className="text-xs text-center py-2">{index + 1}</td>
                                            <td className="text-xs text-center py-2">{items[key].itemid} / {items[key].itemname}</td>
                                            <td className="text-xs text-center py-2">{items[key].itemprice}</td>
                                            <td className="text-xs text-center py-2">{items[key].itemremise}%</td>
                                            <td className="text-xs text-center py-2">{items[key].itemqtt}</td>
                                            <td className="text-xs text-center py-2">{(items[key].itemprice*((100 - items[key].itemremise)/100)) * items[key].itemqtt}</td>
                                        </tr>
                                    )
                                )
                            }
                            
                            
                        </tbody>
                    </table>
            </div>
            <div className="bg-gray-200 w-full h-auto flex flex-row ">
                
                <div className="w-1/2 h-full"></div>
                <div className="w-1/2 h-full flex flex-col pl-8">
                    <div className="w-full h-auto py-2 flex flex-row">
                        <span className="text-sm font-bold text-center capitalize mr-4 pl-2">sous total :</span>
                        <span className="text-sm  text-center capitalize">{total}</span>
                    </div>
                    <div className="w-full h-auto py-2 flex flex-row">
                        <span className="text-sm font-bold text-center capitalize mr-4 pl-2">frais de livraison :</span>
                        <span className="text-sm  text-center capitalize">{total * 0.015}</span>
                    </div>
                    <div className="w-full h-auto py-2 flex flex-row bg-yellow-400">
                        <span className="text-sm font-bold text-center capitalize mr-4 pl-2">total :</span>
                        <span className="text-sm  text-center capitalize">{(parseFloat(total)+parseFloat(total*0.015))}</span>
                    </div>
                </div>
            </div>

        </div>
    </div>                     
     
     
    )
}

export default AdminCP
