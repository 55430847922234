import React, {useEffect, useState }  from 'react'
import { useParams } from 'react-router'
import axios from "axios"
import { Link } from 'react-router-dom'

const SameVolume = (props) =>{
    
    const refreshPage = ()=>{
       //window.location.reload();
       //this.setState({});
     }

    const [volumes, setV] = useState([])
    //const { p } = useParams(); 
    const urlAPI = `https://www.gomispro.com/api/product/volume.php?p=${props.productP}&amp;b=${props.productB}`

        useEffect(() => { 
            axios.get(urlAPI).then(res => { 
            setV(res.data)
            
        }).catch(err => {
            console.log(err)
            })
        }, [])

    return (
        <div className="w-full  h-auto py-2  flex flex-row  items-center">
            {
                
                volumes.length && volumes.map((volume, index) =>
                    <div   onClick={() => {window.location.href=`/product/${volume.idvolume}`}} className="cursor-pointer w-auto h-auto py-2 px-2 border-yellow-400 border align-middle rounded-md font-bold text-sm font-bold text-center m-2 hover:bg-yellow-400">
                        {volume.volume}-{volume.unite}
                    </div>
                )
                //to={`${volume.idvolume}`}
                //console.log(volumes)
               //typeof(volumes)
            }
        </div>
    )
}

export default SameVolume
