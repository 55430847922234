import React, { useEffect, useState } from 'react'
import { Link, useHistory} from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
const LogIn = ()=> {
    
    const [cookies, setCookie] = useCookies(['user']);
    const [u, setU] = useState([])
    const history = useHistory()
    const [user, setUser] = useState("")
    const [password, setPassword] = useState("")
    
   
    const urlAPI = `https://www.gomispro.com/api/user/login.php?user=${user}&pss=${password}`
    
    var userObject = {}
    
    const logIn = () =>{
        if(user == ""  || password == ""){
            alert("voulez-vous saisie vos coordonnées")
        }else{
            axios.get(urlAPI).then(res => { 
                let resultObject = res.data
                
                userObject = resultObject
    
                if(Object.keys(resultObject).length === 0){
                    
                }else{
                    setCookie('user', resultObject, { path: '/' });
                    setCookie('state', "true", { path: '/' });
                    history.push('/profile')
                }
    
            }).catch(err => {
                console.log(err)
                })
        }
       
        
            
    }

    /*
    useEffect(() => { 
       setU(userObject)
    }, [])
    */

    //console.log(userObject)
    return (
        <div className="lg:w-1/2 xs:w-full h-auto flex flex-col  ">
            
            <div  className="w-full h-auto py-2 border-b border-gray-100">
                <span className="text-lg font-semibold border-b-2 border-yellow-400 capitalize py-2 pr-10 leading-tight">
                    Login 
                </span>
            </div>


            <div className="w-full h-auto flex-col flex py-4 px-4 space-y-6">
                <div className="w-full -h-auto">
                    <span className="text-sm capitalize">
                          Content de te revoir! Connectez-vous à votre compte. 
                    </span>
                </div>
                
                <div className="flex flex-col space-y-2">
                    <span className="text-sm font-semibold pl-2">numéro de portable </span>
                    <input type="text" className="w-full h-12 rounded-full border pl-8 focus:border-yellow-400"
                    value={
                            user
                            }
                    onChange={(e)=>setUser(e.target.value)}></input>
                </div>

                <div className="flex flex-col space-y-2">
                    <span className="text-sm font-semibold pl-2">Mot de passe  </span>
                    <input type="password" className="w-full h-12 rounded-full border pl-8"
                    value={password} 
                    onChange={(e)=>setPassword(e.target.value)}></input>
                </div>

                <div className="w-full justify-end flex ">
                    <button className="w-auto h-auto py-2 px-6 bg-yellow-400 rounded-full text-sm font-semibold"
                            onClick={()=>logIn()}>Connexion</button>
                </div>
            </div>
        </div>
    )
}

export default LogIn
