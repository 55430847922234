import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Card from './Card';
import { FaPlay, FaAngleDown } from "react-icons/fa";
const ItemsBrand = () =>{
  const {br } = useParams();  
  const urlAPI = `https://www.gomispro.com/api/brand/brand.php?br=${br}`
  const urlAPIBrands = `https://www.gomispro.com/api/brand/allbrand.php`
    const [products, setProducts] = useState([])
    const [brands, setBrands] = useState([])
    const [brandListState, setBrandListState] = useState(false)
    useEffect(() => {
        axios.get(urlAPI).then(res => { 
          setProducts(res.data)
          //setLoad(true)
          
        }).catch(err => {
          console.log(err)
        })
      }, [])

      useEffect(() => {
        axios.get(urlAPIBrands).then(res => { 
          setBrands(res.data)
          //setLoad(true)
          
        }).catch(err => {
          console.log(err)
        })
      }, [])
      //console.log(brandListState)
      const openBrandLisst = () =>{
        let brandList = document.getElementById("brandList")
        if(brandListState == false){
          setBrandListState(!brandListState)
          brandList.classList.toggle("closeBrandList")
          brandList.classList.add("openBrandList")
        }else{
          brandList.classList.toggle("openBrandList")
          brandList.classList.add("closeBrandList")
          setBrandListState(!brandListState)
        }
      }
    return (
        <div className="w-full h-auto flex lg:flex-row xs:flex-col lg:mt-36 xs:mt-28 lg:px-12 xs:px-2">
          <div className="lg:w-1/6 xs:w-full h-auto bg-gray-100 mr-2 flex flex-col shadow-lg rounded-sm">
              <div className="w-full h-auto bg-yellow-400 flex items-center justify-between px-4 py-4">
                <span className="text-sm font-bold uppercase text-center">nos marques</span>
                <span onClick={()=>openBrandLisst()} className="group">
                    <FaAngleDown className="lg:hidden xs:block group-hover:text-red-400" />
                </span>
              </div>
              <div className="w-full h-auto  px-4 border border-gray-400 border-t-0">
                  <ul className="w-full lg:h-auto xs:h-0 flex flex-col lg:py-2 xs:py-0 closeBrandList overflow-hidden" id="brandList">
                  {
                        brands.map((b, index)=>
                            <Link //to={`/cat/${c.cat}`} 
                                  onClick={() => {window.location.href=`/brand/${b.brand}`}}
                                  className="w-full h-auto py px-2 mx-2  cursor-pointer  hover:bg-yellow-400 hover:shadow-md   hover:text-white">
                                    <div className="flex flex-row  items-center py-1">
                                            <FaPlay className="w-2 h-2 mr-2 text-gray-700" />    
                                            <span className="text-gray-700 capitalize text-sm overflow-ellipsis overflow-hidden ">
                                            
                                              {b.brand}
                                            </span>
                                    </div>
                                
                            </Link>
                            
                        )
                    }
                  </ul>
              </div>
            </div>
          <div className="flex-1 h-auto flex flex-row flex-wrap">
              {
                products.length && products.map((product, index)=>
                <div className="product-card flex flex-col hover:shadow-md lg:py-2 xs:mr-2 hover:bg-gray-100 border  relative overflow-hidden rounded-md ">
                      <div onClick={() => {window.location.href=`/product/${product.idvolume}`}} >
                          {/*
                          <div className="product-card-image mt-2 flex justify-center bg-contain bg-center bg-no-repeat" style={{backgroundImage:`url(${process.env.REACT_APP_url}/product/images/${product.thumb})`}}>
                          
                          </div>
                          */}
                          {/* PRODUCT IMAGE */}
                          <div className="product-card-image ">
                            <img  src={`${process.env.REACT_APP_url}/product/images/${product.thumb}`}
                                  className="w-full h-auto"/>
                          </div>
                          {/** PRODUCT DETAILS */}
                          <div className="card-content flex flex-col  relative z-40 ">
                              <div className="w-full h-auto flex flex-col py-2 my-1">
                               <span className="text-sm  font-bold mb capitalize lg:px-4 xs:px-2 text-red-500">{product.type == "accrédités" ? "Nous consulter, produit réservé aux accrédités" :""}</span>
                                <span className="text-xs  mb capitalize lg:px-4 xs:px-2 ">{product.brand} / {product.red}</span>
                                <span className="text-sm font-bold capitalize text-blue-500 lg:px-4 xs:px-2 truncate">{product.produit}</span>
                              </div>
                              
                              <span className="lg:text-md font-bold  xs:text-xs hover:text-gray-700 font-bold text-gray-800 px-4 flex flex-row justify-between">
                                <p className="text-red-500 flex-row flex text-md" style={{'text-decoration': product.remise > 0 ? "line-through" : ""}}>{product.price}
                                                  . DA
                                </p>
                                <p className="text-green-500 text-xl font-black flex-row animate-bounce" style={{display : product.remise > 0 ? "flex" : "none"}}>{(product.price * ((100 - product.remise)/100))}
                                                . DA
                                </p>
                            </span>
                            <span className="text-xs text-gray-700 pl-4">{product.instock} articles restants.</span>
                            <div className="w-full h-auto  p-2">
                                <div className="w-full h-2 bg-red-200 rounded-full flex ">
                                    <div className="h-2 bg-yellow-500 rounded-full" style={{width: product.instock == "0" ? "0px" : `${((product.instock*100)/(product.instock + product.sold))}%` }}></div>
                                </div>
                            </div>
                              {/*
                              <div className="w-full h-auto pt-2 pb-2 flex flex-row justify-between pl-4 pr-4">
                                  <button className="h-8 p-2 flex flex-1 rounded-md bg-yellow-100 text-center card-button">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                        </svg>
                                        <span className="text-xs capitalize font-bold ">ajouter au panier</span>
                                  </button>
                                  <button className="w-8 h-8 p-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                    </svg>
                                  </button>
                              </div>
                              */}
                          </div>
                  </div>
                  </div>
                
                )
              }
          </div>
        </div>
    )
}

export default ItemsBrand
