import React, {useEffect, useState} from 'react'
import axios from 'axios';
const StockLog = () =>{
    const [products, setProducts] = useState([])
    const [ref, setRef] = useState("")
    const [fact, setFact] = useState("")
    const urlAPI = `https://www.gomispro.com/api/product/stocklog.php`
      useEffect(() => {
            axios.get(urlAPI).then(res => { 
            setProducts(res.data)

            }).catch(err => {
            console.log(err)
            })
        }, [])

        const getThisRef = (sb)=>{
            //setRef(r)
            console.log(sb)
            let x = "h-12 border-b border-gray-300 py-2"
            if(sb == ""){
              resetTable()
            }else{
               products.length && products.map((p, index) =>
                 { 
                   let tr = "tr"+index
                   let row = document.getElementById(tr) 
                   let ref = p.red 
                   //row.classList = ''
                   if(ref.toLowerCase().includes(sb.toLowerCase())){
                    
                     row.classList.add("bg-green-300")
                   }else{
                     
                     row.classList.add("hidden")
                   }
                 }
               )
            }
        }

        const getThisFct = (sb)=>{
          //setRef(r)
          let x = "h-12 border-b border-gray-300 py-2"
          if(sb == ""){
            resetTable()
          }else{
             products.length && products.map((p, index) =>
               { 
                 let tr = "tr"+index
                 let row = document.getElementById(tr) 
                 let fact = p.fact 
                 //row.classList = ''
                 if(fact.toLowerCase().includes(sb.toLowerCase())){
                  
                   row.classList.add("bg-green-300")
                 }else{
                   
                   row.classList.add("hidden")
                 }
               }
             )
          }
      }

        const resetTable = () => {
            products.length && products.map((p, index) =>
              {
                let tr = "tr"+index
                let row = document.getElementById(tr)
                row.classList.remove("hidden")
                row.classList.remove("bg-green-300")
                //row.classList.add("h-12 border-b border-gray-300 py-2") 
              }
            )
          }

        const searchThisRef = (sb) =>{
            
            let x = "h-12 border-b border-gray-300 py-2"
            if(sb == ""){
               resetTable()
            }else{
               products.length && products.map((p, index) =>
                 { 
                   let tr = "tr"+index
                   let row = document.getElementById(tr) 
                   let ref = p.red 
                   row.classList = ''
                   if(ref.toLowerCase().includes(sb.toLowerCase())){
                    
                     row.classList.add("bg-green-300")
                   }else{
                     
                     row.classList.add("hidden")
                   }
                 }
               )
            }
        }

        const deleteRow = (id, idv, qtt) => {
          const urlAPIDELETE = `https://www.gomispro.com/api/product/deleterow.php?id=${id}&idv=${idv}&q=${qtt}`
          axios.get(urlAPIDELETE).then(res => { 
              //refreshPage()
            }).catch(err => {
            console.log(err)
            })
        } 
        
        const refreshPage = ()=>{
          window.location.reload();
       }
    return (
        <div className="w-full h-auto px-12 py-4">
                <div className='w-full h-12 flex flex-row bg-yellow-400 items-center justify-center'>
                    <div>
                    <span className='text-sm uppercase text-gray-800 mr-4'>FACTURE D ENTRER :</span>
                    <input type="text" 
                           className='w-1/4 h-10 border rounded-md px-2' 
                           placeholder='N° FACT ...'
                           onChange={(e)=>getThisFct(e.target.value)}></input>
                    </div>
                    <div>
                    <span className='text-sm uppercase text-gray-800 mr-4'>chercher par referance :</span>
                    <input type="text" 
                           className='w-1/4 h-10 border rounded-md px-2' 
                           placeholder='Ref ...'
                           onChange={(e)=>getThisRef(e.target.value)}></input>
                    </div>
                    
                </div>
                <div className="w-full h-auto flex flex-col ">
                        {
                           products.length && products.map((product, index) =>
                               <div className="w-full h-12 items-center justify-center px-2 flex flex-row border hover:bg-gray-200"  
                                    style={{"background": product.etat == 1 ? "#F87474" : "#fff" }}
                                    id={`tr${index}`}>
                                            <div className="w-1/6">{product.etat == 1 ? "errer : retiré du stock" : ""}</div>
                                            <div className="w-1/6">{product.fact}</div>
                                            <div className="w-1/6">{product.id}</div>
                                            <div className="w-1/6">{product.d}</div>
                                            <div className="w-1/6">{product.idvolume}</div>
                                            <div className="w-1/6">{product.red}</div>
                                            <div className="w-1/6">{product.produit}</div>
                                            <div className="w-1/6">{product.brand}</div>
                                            <div className="w-1/6">{product.volume}-{product.unite}</div>
                                            <div className="w-12 h-12 px-2 bg-yellow-500 text-center">
                                              <span >+{product.qtt}</span>
                                            </div>
                                            <div className="w-1/6">{product.fact}</div>
                                            <span style={{display: product.etat == 1 ? "none" : "block"}} className="group" onClick={()=>deleteRow(`${product.id}`, `${product.idvolume}`, `${product.qtt}`)}> 
                                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 group-hover:text-red-400 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                              </svg>
                                            </span>
                                            
                                        </div>
                           )
                        }
                        
                </div>
        </div>
    )
}

export default StockLog
