import React, {useEffect, useState} from 'react'
import axios from 'axios';
import produce from 'immer';

const Facture = () =>{
    const [search, setSearch] = useState('')
    const [item, setItem] = useState()
    const [products, setProducts] = useState([])
    //const [result, setResult] = useState([])

    const urlAPI = `https://www.gomispro.com/api/admin/items.php`

    useEffect(() => {
        axios.get(urlAPI).then(res => { 
          setProducts(res.data)
        }).catch(err => {
          console.log(err)
        })
      }, [])

      
      const searchItem = (si) =>{
        //const result = []
        const result_list = document.getElementById("result_list")
        const productitems = document.getElementsByClassName("productitems")
        //let obj = products.find(o =>  o.ref.toLowerCase().includes(si.toLowerCase()));
        //result.push(obj)
        //console.log(obj)
        //o.produit === si ||
        //item.toLowerCase().includes(sb.toLowerCase())
        //result_list.classList.remove("hidden")
        result_list.classList.add("flex")
        //productitems.classList.add()
        products.length && products.map((product, index)=>
            {
                if(product.ref.toLowerCase().includes(si.toLowerCase())){
                      let li_item = document.createElement("li")
                      //li_item.textContent = product.produit 
                      //result_list.appendChild(li_item) 
            }
        }
        )
      }
      console.log(search)
    return (
        <div className="w-full h-auto bg-gray-700 px-12 py-4">
            <div className="w-full h-48 bg-white border border-gray-600 shadow-md ">

            </div>
            <div className="w-full h-12 bg-white border border-gray-600 shadow-md flex flex-col items-center justify-center relative">
                <div className="w-full h-12 flex flex-row items-center justify-center">
                    <span className="text-xs uppercase">entrer le nom ou bien le code du produit .</span>
                    <input type="text" 
                           className="w-1/3 h-10 px-4 border rounded-md text-sm font-bold" 
                           onChange={(e)=>setSearch(e.target.value)}
                           
                            />
                </div>
                <div className="w-full h-auto bg-yellow-500 absolute z-20 top-12">
                    <ul className="w-full h-auto flex flex-col " id="result_list">
                      
                           {
                               products.filter(({val}) =>{
                                if(search == ""){
                                    return val  
                                } else if(val.produit.toLowerCase().include(search.toLowerCase())){
                                    return val
                                }                                  
                               }).map((val,index)=>
                                <li className="w-full h-10 border-b border-gray-200 flex flex-row items-center" key={index}>
                                        <div className="h-8 w-1/3 "><span>{val.brand}</span> -  </div>
                                        <div className="h-8 w-1/3 "><span>{val.produit}</span> -  </div>
                                        <div className="h-8 w-1/3 "><span>{val.volume}/{val.unite}</span> </div>
                                    </li>
                               )
                               /*
                                products.length && products.map((p,index)=>
                                <li className="w-full h-10 border-b border-gray-200 hidden flex-row items-center" key={index}>
                                    <div className="h-8 w-1/3 "><span>{p.brand}</span> -  </div>
                                    <div className="h-8 w-1/3 "><span>{p.produit}</span> -  </div>
                                    <div className="h-8 w-1/3 "><span>{p.volume}/{p.unite}</span> </div>
                                </li>
                            )*/
                           }
                        
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Facture
