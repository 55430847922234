import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';

const BrandsList = ()=> {
    const [b, setB] = useState([])

    const urlAPI = `https://www.gomispro.com/api/brand/allbrand.php`
    
    useEffect(()=>{
        axios.get(urlAPI).then(res => { 
            setB(res.data)
            //setLoad(true)
            
            }).catch(err => {
            console.log(err)
            })
    }, [])
    
    const refreshPage = ()=>{
        window.location.reload();
     }
   const deleteItem = (id) =>{
    const urlAPIdelete = `https://www.gomispro.com/api/brand/deletebrand.php?id=${id}`
    axios.get(urlAPIdelete).then(res => { 
                
                }).catch(err => {
                console.log(err)
                })
                refreshPage()
   }
    return (
        <div className="w-full h-auto flex flex-col px-12"> 
            <div className="w-full h-auto flex flex-row justify-end items-center py-4">
                <span className="text-sm font-bold px-4">ajouter une marque </span>
                <Link to={"/newbrand"} className="w-auto h-auto px-4 py-2 bg-yellow-400 text-gray-700 rounded-md capitalize">ajouter</Link>
            </div>
            <div className="w-full h-12 px-2 bg-gray-700 flex flex-row items-center">
                    <div className="w-1/3 h-auto ">
                        <span className="text-sm font-bold text-white capitalize">logo</span>
                    </div>
                    <div className="w-1/3 h-auto ">
                        <span className="text-sm font-bold text-white capitalize">marque</span>
                    </div>
                    <div className="w-1/3 h-auto ">
                        <span className="text-sm font-bold text-white capitalize">actions</span>
                    </div>
            </div>
            <div className="w-full h-auto">
                {
                    b.length && b.map((brand, index)=>
                    <div className="w-full h-12 px-2 border-b border-b-gray-100 flex flex-row items-center">
                            <div className="w-1/3 h-auto ">
                                
                            </div>
                            <div className="w-1/3 h-auto ">
                                <span className="text-sm font-bold text-gray-700 capitalize">{brand.brand}</span>
                            </div>
                            <div className="w-1/3 h-auto flex flex-row items-center justify-center">
                                    {/** edit button */}
                                    <Link to={`/editebrand/${brand.id}`} className="w-10 h-10 bg-yellow-400 rounded-lg flex items-center justify-center mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                        </svg>
                                    </Link>
                                    {/** delete button */}
                                    <button className="w-10 h-10 bg-red-500 rounded-lg flex items-center justify-center mr-2"
                                        onClick={()=>deleteItem(`${brand.id}`)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                        </svg>
                                    </button>
                                    
                            </div>
                    </div>
                    )
                }
            </div>
        </div>
    )
}

export default BrandsList
