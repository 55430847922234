import React from 'react'
import AdminNav from '../../components/admin/AdminNav'
import MostVisited from '../../components/admin/MostVisited'
import AllSales from '../../components/admin/AllSales'

const  salesStat = () =>{
  return (
    <div>
        <AdminNav />
        <AllSales />
    </div>
  )
}

export default salesStat 