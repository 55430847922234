import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import Slider from 'react-slick'
const axios = require('axios');

const SlideShow = () => {

    const [slide, setSlide] = useState([])
    const urlAPI = `https://www.gomispro.com/api/slider/slide.php`
    useEffect(() => {
        axios.get(urlAPI).then(res => { 
          setSlide(res.data)
          //setLoad(true)
          
        }).catch(err => {
          console.log(err)
        })
      }, [])

      const settings = {
        infinite:true,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        cssEase: "linear"
    }

    return (
            <div className="w-full h-96  mt-36 overflow-hidden bg-no-repeat bg-left-bottom bg-cover" style={{backgroundImage:`url(${require("../images/banner/background.jpg").default})`}}>
               <Slider {...settings} className="w-auto h-auto py-2 ">
                   {
                    slide.length && slide.map((s, index)=>
                        <div>
                            <div className="w-full h-96 flex flex-row justify-center px-12 ">{/**  */}
                            <div className="lg:w-96 lg:h-96  xs:w-full xs:h-96 xs:px-4 flex flex-col justify-center overflow-hidden  lg:relative xs:absolute lg:bg-transparent xs:bg-gray-700 xs:bg-opacity-10">
                                <div className=" w-full h-auto">
                                    <span className="lg:text-xl xs:text-sm lg:text-gray-400 xs:text-yellow-300 font-bold uppercase mb-2">
                                        {s.brand}
                                    </span>
                                </div>
                                <div className="lg:w-full xs:w-72 h-auto">
                                    <span className="lg:text-4xl xs:text-md lg:text-gray-700 xs:text-yellow-400 uppercase mb-2 font-black break-all">
                                        {s.produit}
                                    </span>
                                </div>
                                <div className="lg:w-full xs:w-72 h-48 mb-2 whitespace-pre-line overflow-hidden xs:text-sm py-2 lg:text-gray-600 xs:text-gray-100">
                                    {s.descript}
                                    
                                </div>
                                <div>
                                    <Link to={`/product/${s.idvolume}`} className="w-auto h-auto px-8 py-2 bg-yellow-300 rounded-md shadow-sm capitalize">
                                         découvrir
                                    </Link>
                                </div>
                            </div>
                            <div className="w-96 h-96 flex justify-center items-center relative" >
                                <div className="w-72 h-72 bg-cover" style={{backgroundImage:`url(https://www.gomispro.com/api/product/images/${s.thumb})`}}></div>
                            </div>
                        </div>

                        </div>
                    ) 
                }
                </Slider>
            </div>
    )
}

export default SlideShow
