import React, {useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios"
import { increment } from '../redux/cartCounter'
import { addtoamount } from '../redux/totalInCart'
import quantity, { incrementQtt, decrementQtt } from '../redux/quantity'
import SameVolume from './SameVolume';
import ImagesList from './ImagesList';

const MainItem = (props) => {
    const producthumb = useRef()
    let cartItems = JSON.parse(localStorage.getItem("cartitems"))
    let cn = localStorage.getItem("cartnumber")
    let totalInCart = localStorage.getItem("totalincart")
    
    let idv
    const quant = useSelector((state)=> state.quantity.qtt)
    const dispatch = useDispatch();
    
    const dispatchIcrementQtt = () =>{
       
        dispatch(incrementQtt());
    }

    const dispatchdecrementQtt = () =>{
       
        dispatch(decrementQtt());
    }

    const disp = () => {
            dispatch(increment());
    }
    
    const alterAmount = (q,p) => {
            let amount = q*p 
            dispatch(addtoamount(amount));
    }
   
    const handelAddToCart = (Object, quant) =>{
       
     
       const item ={
            itemid:Object.idvolume,
            itemname:Object.red+" - "+Object.produit,
            itembrand:Object.brand,
            itemprice:Object.price,
            itemvolum:Object.volume,
            itemthumb:Object.thumb,
            itemqtt:quant,
            itemremise:Object.remise
        }
        idv = item.itemid
        //reactLocalStorage.setObject('cartitems', { `${idv}` : item});
      
        if(cartItems != null){
            if(cartItems[item.itemid] == undefined){
               // console.log("! in")
                //let cii =  Object.assign(cartItems, o)
                const i = item.itemid
                cartItems[i] = item
                //localStorage.setItem("cartitems", JSON.stringify(cartItems))
                
                alterAmount(item.itemqtt,(item.itemprice * ((100 - item.itemremise)/100)));
                disp();
                
                totalInCart = JSON.parse(totalInCart) + (item.itemqtt * (item.itemprice * ((100 - item.itemremise)/100)))   
                //alert(typeof(JSON.parse(totalInCart)))
                cn = parseInt(JSON.parse(cn)) + 1
                //localStorage.setItem('cartnumber', JSON.stringify(cn))
                //localStorage.setItem('totalincart', JSON.stringify(totalInCart))
            }else{
                //console.log(" in")
                alterAmount(item.itemqtt,(item.itemprice * ((100 - item.itemremise)/100)));
                totalInCart = JSON.parse(totalInCart) + (item.itemqtt * (item.itemprice * ((100 - item.itemremise)/100)))
                cartItems[item.itemid].itemqtt = parseInt(cartItems[item.itemid].itemqtt) + parseInt(item.itemqtt);
                //localStorage.setItem('cartnumber', JSON.stringify(cn))
                //localStorage.setItem('totalincart', JSON.stringify(totalInCart))
            }
            
        }else{
            
            //console.log("null")
            let o = {}
            let k = item.itemid
            let v = item
            o[k] = v
           //localStorage.setItem("cartitems", JSON.stringify(o))
           cartItems = o
           totalInCart = (item.itemqtt * (item.itemprice * ((100 - item.itemremise)/100))) 
           //totalInCart = (item.itemqtt * item.itemprice) 
           cn =  1 ;
           disp();
           alterAmount(item.itemqtt,parseFloat((item.itemprice * ((100 - item.itemremise)/100))));
           //localStorage.setItem('cartnumber', JSON.stringify(cn))
           //localStorage.setItem('totalincart', JSON.stringify(totalInCart))
        }
        
        localStorage.setItem('cartitems', JSON.stringify(cartItems))
        localStorage.setItem('cartnumber', JSON.stringify(cn))
       
        localStorage.setItem('totalincart', JSON.stringify(totalInCart))
    
    }

    const handelAddToCartt = (Object, quant) =>{
            // start animation
        let producthumb = document.getElementById("producthumb")
        producthumb.classList.toggle("hidden")
        producthumb.classList.add("transform")
        producthumb.classList.add("scale-50")
        producthumb.classList.add("delay-300")
        producthumb.classList.add("duration-5000")
        /*
        producthumb.classList.add("transition")
        producthumb.classList.add("delay-150")
        producthumb.classList.add("duration-1500")
        producthumb.classList.add("ease-in-out")
        //producthumb.classList.add("transform")
        //producthumb.classList.add("scale-50")
        //producthumb.classList.add("transition-none")
        */
    }
    /*
    const refreshPage = ()=>{
        window.location.reload();
     }
     */

    const { id , brand } = useParams();
    //const [idd, setIdd] = useState(props.productid);
    const [products, setProduct] = useState([])
    //const [hasImages, setHasImages] = useState()
    //const [imgarray, setImgArray] = useState([])
    
    

    const [qtt, setQtt] = useState()
    //console.log(this.props.location.id)
        const urlAPI = `https://www.gomispro.com/api/product/product.php?idv=${id}`
        
        useEffect(() => {
            axios.get(urlAPI).then(res => { 
            setProduct(res.data)

        }).catch(err => {
            console.log(err)
            })
        }, [])
        
        console.log("======================")
        console.log(products)
        let likes = Math.floor(Math.random() * 201)
        
    return (
        <div className="lg:w-3/4 lg:h-auto  xs:w-full xs:h-auto lg:mt-20 xs:mt-2">
            {products.length && products.map((product, index) =>
                             
                                       
                <div className="w-full h-auto flex flex-col divide-y-2 ">
                                    
                        <div className="w-full h-auto mb-4 py-4 flex lg:flex-row xs:flex-col ">
                            <div className="flex flex-col">
                                <div className="product-detail-image bg-contain bg-no-repeat bg-center flex items-center justify-center " 
                                    ref={producthumb}
                                    
                                     style={{backgroundImage:`url(${process.env.REACT_APP_url}/product/images/${product.thumb})`}}>
                                    
                                </div>
                                {/*
                                <ImagesList images={product.images} />           
                                                */}
                                
                                
                                
                            </div>
                            
                    
                            <div className="flex flex-col flex-1  divide-y devide-gray-700 pl-4 pr-4 pb-2 lg:border-0 xs:border-t-4 xs:border-yellow-500 xs:rounded-xl -mt-20 py-2">
                             <div className="w-full h-auto flex flex-col pl-4">
                                <span className="text-xs capitalize pb-2 hover:text-blue-400 cursor-pointer" id="brand"
                                onClick={() => {window.location.href=`/brand/${product.brand}`} }>{product.brand} / {product.red}</span>
                                <span className="text-2xl font-black uppercase" id="product">{product.produit} - {product.volume}{product.unite}</span>
                                <span className="font-medium py-4 xs:text-xs capitalize text-green-500"
                                style={{display : product.instock > 0 ? "flex" : "none"}}
                                >disponible</span>
                                <span className="font-medium py-4 xs:text-xs capitalize text-red-400"
                                style={{display : product.instock == 0 ? "flex" : "none"}}
                                >hors stock</span>
                             </div>
                             
                             <div className="flex flex-row h-auto w-full pl-4 py-2">
                                    <span className="pr-2 font-bold text-green-400">{likes}</span>
                                    <span className="capitalize pr-6 xs:text-xs">personne aimes ce produit</span>
                                    <button classsName="">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-400" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                    </svg>
                                    </button>
                             </div>

                             <div className="lg:py-4 lg:px-4 xs:px-0 h-auto w-full">
                                 <div classsName="w-full h-auto pb-4">
                                    <ul className="pl-4 hidden">
                                        <li className="text-sm">Pair and Play with your Bluetooth® device with 30' range</li>
                                        <li className="text-sm">Pair and Play with your Bluetooth® device </li>
                                        <li className="text-sm">Pair and Play with your Bluetooth® device with 30' </li>
                                        <li className="text-sm">Pair and Play with your Bluetooth® </li>
                                    </ul>
                                 </div>

                                 <div className="h-auto w-full py-2">

                                    <span className="text-3xl font-semibold flex flex-row mb-4" id="price" style={{'text-decoration': product.remise > 0 ? "line-through" : ""}}>
                                        {product.price}
                                        .DA
                                    </span>

                                    <span className="text-4xl font-bold flex flex-row text-yellow-400" id="price" style={{display : product.remise > 0 ? "flex" : "none"}}>
                                        {(product.price * ((100 - product.remise)/100))}
                                           .DA
                                    </span>
                                 </div>
                                <div className="h-auto w-full py-2 flex flex-col">
                                    <span className="text-sm capitalize ">disponible aussi dans ces volumes :</span>
                                    <SameVolume productP={product.produit} productB={product.brand}/>
                                 </div> 
                                 <div classsName="w-full h-auto flex flex-col lg:px-4 lg:py-4 xs:px-0">
                                     <div className="flex flex-row mb-4">
                                         <span className="w-10 h-10 bg-gray-50 border border-gray-100 px-2 py-2 hover:bg-yellow-400" 
                                            style={{display: quant <= 1 ? "none" : "block"}}
                                         onClick={
                                             () => dispatchdecrementQtt()
                                         } >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 12H6" />
                                            </svg>
                                         </span>

                                         <input type="text" required disabled min="1" max="3" value={quant} className=" border border-gray-100  bg-gray-50 w-24 h-10 px-4 py-2 text-center" onChange={
                                                (e)=>{
                                                    setQtt(e.target.value);
                                                }
                                            }/>

                                         <span className="w-10 h-10 bg-gray-50 border border-gray-100 px-2 py-2 hover:bg-yellow-400" 
                                            style={{display: quant >= product.instock ? "none" : "block"}}
                                         onClick={
                                            () => dispatchIcrementQtt()
                                         }>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                            </svg>
                                         </span>
                                     </div>

                                    
                                        
                                    <button onClick={() =>  
                                            
                                        handelAddToCart(product, quant)  
                                    
                                    } 
                                        disabled = {product.instock < 1 ? true : false}
                                        className= {product.instock > 0 ? "bg-yellow-400 px-6 py-2 w-auto text-white font-semibold hover:bg-black rounded-full" : "px-6 py-2 w-auto hover:text-white font-semibold hover:bg-black rounded-full border border-black"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 h-6 w-4 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                        </svg>
                                        Ajouter au panier </button>
                                 </div>
                             </div>

                             
                       </div>
                       

                        </div>

                        <div className="w-full h-auto py-4 px-4">
                            <div>
                                <span className="text-2xl font-bold text-black">
                                    à propos de ce produit 
                                </span>
                            </div>
                            <div className="w-full px-4">
                                <span className="xs:text-xs lg:text-sm">
                                    {product.descript}
                                </span>
                                
                            </div>
                            
                        </div>
                </div>
                
            )}
        </div>
    )
}

export default React.memo(MainItem)
