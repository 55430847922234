import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router';
import axios from 'axios';


const Cat=() =>{
    const {ct } = useParams();  
    const urlAPICAT = `https://www.gomispro.com/api/categorie/itemscat.php?ct=${ct}`
    const [products, setProducts] = useState([])
    
    
    
     useEffect(() => {
        axios.get(urlAPICAT).then(res => { 
          setProducts(res.data)
          console.log("data reloaed :"+ct)
          //setLoad(true)
           
        }).catch(err => {
          console.log(err)
        })
      }, [])
    return (
        <div className="w-full h-auto flex flex-row flex-wrap space-y-2 mt-36 lg:px-12 xs:px-2">
             {
              products.length && products.map((product, index)=>
              <div className="product-card flex flex-row justify-between cursor-pointer overflow-hidden">
                    <div onClick={() => {window.location.href=`/product/${product.idvolume}`}} >
                      <div className="product-card flex flex-col hover:shadow-md lg:py-2 xs:mr-2 border hover:bg-gray-100">
                        
                        <img  src={`${process.env.REACT_APP_url}/product/images/${product.thumb}`}
                              className="product-card-image" />
                        {/*
                        <div className="product-card-image mt-2 flex justify-center bg-contain bg-center bg-no-repeat" 
                              style={{backgroundImage:`url(${process.env.REACT_APP_url}/product/images/${product.thumb})`}}>
                        
                        </div>
                        */}
                        <div className="w-full h-auto flex flex-col py-2 border-t border-gray-200 my-2">
                          <span className="text-xs font-semibold mb capitalize lg:px-4 xs:px-2 ">{product.brand}</span>
                          <span className="text-sm font-bold capitalize text-blue-500 lg:px-4 xs:px-2 truncate">{product.produit}</span>
                        </div>

                        <div className="lg:text-md font-bold  xs:text-xs hover:text-gray-700 font-bold text-gray-800 px-4 flex flex-row justify-between">
                                <p className="text-red-500 flex-row flex text-md" style={{'text-decoration': product.remise > 0 && product.instock > 0 ? "line-through" : ""}}>{product.price}
                                                  - DA
                                </p>
                                <p className="text-green-500 text-xl font-black flex-row animate-bounce" style={{display : product.remise > 0  & product.instock > 0 ? "flex" : "none"}}>{(product.price * ((100 - product.remise)/100))}
                                                . DA
                                </p>
                            </div>
                        

                        <div className="w-full h-auto pt-2 pb-2 hidden flex-row justify-between pl-4 pr-4">
                            <button className="w-8 h-8 p-2">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                  </svg>
                            </button>
                            <button className="w-8 h-8 p-2">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                              </svg>
                            </button>
                        </div>
                    </div>
            
                </div>
                </div>
              
              )
            }
        </div>
    )
}

export default Cat
