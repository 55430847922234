import React from 'react'
import AdminNav from '../../components/admin/AdminNav'
import TicketLb from '../../components/admin/TicketLb'

const ticket = () =>{
  return (
    <div>
        <AdminNav />
        <TicketLb />
    </div>
  )
}

export default ticket