import React, { useState, useEffect } from 'react'
import { useRef } from 'react';
import { useParams } from 'react-router'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import axios from 'axios'


const StatByClient = () =>{
    const { tel } = useParams(); 
    const [client, setClient] = useState([])
    const [Orders, setOrders] = useState([])
    const [startDate, setStartDate] = useState(new Date());
    const [ttl, setTotal] = useState(0)
    const urlAPIClientstt  = `https://www.gomispro.com/api/orders/clientst.php?tl=${tel}`
    const urlAPIClientOrd  = `https://www.gomispro.com/api/orders/clientord.php?tl=${tel}`
    
    console.log("DATE")
    console.log(startDate)

    useEffect(() => {
        axios.get(urlAPIClientstt).then(res => { 
           setClient(res.data)
          }).catch(err => {
            console.log(err)
          })
    }, [])

    useEffect(() => {
        axios.get(urlAPIClientOrd).then(res => { 
           setOrders(res.data)
          }).catch(err => {
            console.log(err)
          })
    }, [])

    console.log(client)


    const resetTable = () => {
        Orders.length && Orders.map((p, index) =>
          {
            let tr = "tr"+index
            let row = document.getElementById(tr)
            row.classList.remove("hidden")
            row.classList.remove("bg-green-300")
            //row.classList.add("h-12 border-b border-gray-300 py-2") 
          }
        )
      }

    let income = 0
    const handleCalendarClose2 = () =>{
      let sd = document.getElementById("selctdate2").value
      resetTable()
      Orders.length && Orders.map((p, index) =>
      { 
        let tr = "tr"+index
        let row = document.getElementById(tr) 
        let d = p.date 
        let i = p.total
         
        //row.classList = ''
        if(d.toLowerCase().includes(sd.toLowerCase())){
         //console.log(p.total)
          row.classList.add("bg-green-300")
          income = income + parseFloat(i)
        }else{
          
          row.classList.add("hidden")
        }
      }
 )
 setTotal(income)
      
    }

    const handleCalendarClose = () =>{
        let sd = document.getElementById("selctdate").value
        resetTable()
        Orders.length && Orders.map((p, index) =>
        { 
          let tr = "tr"+index
          let row = document.getElementById(tr) 
          let d = p.date 
          let i = p.total
           
          //row.classList = ''
          if(d.toLowerCase().includes(sd.toLowerCase())){
           //console.log(p.total)
            row.classList.add("bg-green-300")
            income = income + parseFloat(i)
          }else{
            
            row.classList.add("hidden")
          }
        }
   )
   setTotal(income)
        
      }

  return (
    <div className='w-full h-auto flex flex-col py-4 px-2'>
           
            <div className='w-full h-48 bg-grey-100 border border-grey-200 mt-2'>
                    {
                        client.length && client.map((cl, index)=>
                        <div className='w-full h-auto flex flex-row'>
                                <div className='w-96 h-48 bg-blue-400 flex flex-col justify-center items-center mx-2'>
                                    <p className='font-bold text-white'>{ cl.firstname}</p>
                                    <p className='font-bold text-white'>{ cl.lastname}</p>
                                    <p className='font-bold text-white'>{ cl.tel}</p>
                                </div>
                                <div className='w-96 h-48 bg-blue-400 flex flex-col justify-center items-center mx-2'>
                                    
                                    <p className='text-5xl font-bold text-white'>{ cl.tot} .DZD</p>
                                    <p className='text-white'>Total Des Commandes</p>
                                </div>
                                <div className='w-48 h-48 bg-blue-400 flex flex-col justify-center items-center '>
                                    <p className='text-7xl font-bold text-white'>{ cl.num}</p>
                                    <p className='text-white'>Commandes</p>
                                </div>
                        </div>
                        )
                    }
            </div>

            <div className='w-full h-auto bg-yellow-400 px-10 py-2 my-2 flex flex-row'>
                    <div className='flex flex-col'>
                        <div className='flex flex-row'>
                            <p className='text-sm w-auto px-2'>recherche par mois :</p>
                            <DatePicker id='selctdate'
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                selected={startDate} 
                                onChange={(date) =>  setStartDate(date)}
                                onCalendarClose={handleCalendarClose}
                                className="h-8 w-72 rounded text-center"
                                />
                        </div>
                        <div className='flex flex-row'>
                            <p className='text-sm w-auto px-2'>recherche par année :</p>
                            <DatePicker id='selctdate2'
                                dateFormat="yyyy"
                                showMonthYearPicker
                                selected={startDate} 
                                onChange={(date) =>  setStartDate(date)}
                                onCalendarClose={handleCalendarClose2}
                                className="h-8 w-72 rounded text-center"
                                />
                        </div>
                    </div>
                        <p className='w-1/3 text-black font-bold'>total des achats et de  : {ttl} .dzd</p>
            </div>


            <div className='w-full h-auto bg-grey-100 border border-grey-200 mt-2 flex flex-row flex-wrap '>
                    
                    {
                         Orders.length && Orders.map((ord, index)=>
                            <div className='w-48 h-48 bg-grey-600 flex flex-col items-center border border-grey-200 rounded-md mx-2' id={`tr${index}`}>
                                <div className='w-24 h-24'>
                                    <img className="h-24 w-24" src={require('../../images/colis.png').default}/>
                                </div>
                                <p className='text-sm'>Facture : {ord.ido}</p>
                                <p className=''>Le : {ord.date}</p>
                                <p className='text-sm'>Total : {ord.total}</p>
                            </div>
                         )
                    }
            </div>
    </div>
  )
}

export default StatByClient