import React, {useEffect, useState} from 'react'
import { Link, useHistory} from 'react-router-dom';
import axios from 'axios';




const ListOrders = () =>{
    const [orders, setOrders] = useState([])
    const [p, setP] = useState([])
    const [search, setSearch] = useState()

    const refreshPage = ()=>{
      window.location.reload();
   }

    //const br = useRef(null)
    const urlAPI = `https://www.gomispro.com/api/orders/allorders.php`

      useEffect(() => {
            axios.get(urlAPI).then(res => { 
            setOrders(res.data)
            //setLoad(true)
            
            }).catch(err => {
            console.log(err)
            })
        }, [])
        
       
       
       const  valider = (id) =>{
        var timer = 0;
        var delay = 200;
        var prevent = false;

        const urlAPICONFIRM = `https://www.gomispro.com/api/orders/confirm.php?id=${id}`
       
        timer = setTimeout(function(){
          if(!prevent){
              axios.get(urlAPICONFIRM).then(res => { 
                refreshPage()
                //setLoad(true)
                
                }).catch(err => {
                console.log(err)
                })
          }
          prevent = false;
        },delay)
        alert(" Facture Validée !")
       }

       const  deleteOrder = (id) =>{
        const urlAPICONFIRM = `https://www.gomispro.com/api/orders/delete.php?id=${id}`
        axios.get(urlAPICONFIRM).then(res => { 
          refreshPage()
          //setLoad(true)
          
          }).catch(err => {
          console.log(err)
          })
       }
       /*
       const deleteItem = (id) => {
          const urlAPIDELETE = `https://apis.gomispro.com/product/delete.php?id=${id}`
          axios.get(urlAPIDELETE).then(res => { 
              refreshPage()
            }).catch(err => {
            console.log(err)
            })
        } 
        */
    return (
        <div className="w-full h-auto overflow-hidden flex flex-col px-12">
                <table className="table-fixed">
                  <thead className="bg-yellow-400">
                    <tr className="text-sm text-left h-12 border-b border-gray-700 bg-gray-200 px-2" >
                      <th>N°</th>
                      <th>Date</th>
                      <th>Nom</th>
                      <th>Prénom</th>
                      <th>total</th>
                      <th>Transport</th>
                      <th>actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      orders.length && orders.map((order, index)=>
                      <tr className="h-12 border-b border-gray-300 py-2 hover:bg-gray-200" >
                      <td>{order.ido}</td>
                      <td className="flex flex-row ">{order.date}</td>
                      <td>{order.firstname}</td>
                      <td>{order.lastname}</td>
                      <td>{order.total}</td>
                      <td>{order.shipping}</td>
                     
                     <td>
                        <div className="flex flex-row">
                            {/* edit */}
                            <Link to={`/order/${order.ido}`} className="w-auto h-auto p-2 hover:bg-yellow-400 group rounded-md shadow-md">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-yellow-500 group-hover:text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                  </svg>
                            </Link>

                            <button className="w-auto h-auto p-2 hover:bg-yellow-400 group rounded-md shadow-md"
                                    onClick={()=>valider(order.ido)}>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500 group-hover:text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                              </svg>  
                            </button>  
                            
                            <button className="w-auto h-auto p-2 hover:bg-yellow-400 group rounded-md shadow-md"
                                    onClick={()=>deleteOrder(order.ido)}>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500 group-hover:text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                              </svg>  
                            </button>  
                        </div>
                        
                      </td>
                    </tr>
                      )
                    }
                  </tbody>
            </table>
        </div>
    )
}

export default ListOrders
