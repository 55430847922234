import Home from "../pages/home";
import Product from "../pages/product"
import Cart from "../pages/cart"
import Account from "../pages/account"
import Checkout from "../pages/checkout";
import PayPal from "../pages/PayPal";
import { Route, Switch, BrowserRouter as Router} from "react-router-dom";
import Facturation from "../pages/Facturation";
import Success from "../pages/success";
import Cancelled from "../pages/cancelled";
import Brand from "../pages/brand";
import Categorie from "../pages/categorie";
import Payment from "../pages/payment";
import Profile from "../pages/Profile";
//import Dashboard from "../pages/user/dashboard";
//import Securite from "../pages/user/securite";
//import UserOrders from "../pages/user/userorders";
//import userDetails from "../pages/user/userdetails";
import Promotions from "../pages/promotions";

// ADMIN 
import adminLog from "../pages/admin/adminlog";
import Items from "../pages/admin/items";
import AddItem from "../pages/admin/additem";
import UpdateItem from "../pages/admin/updateItem";
import Orders from "../pages/admin/allOrdes";
import ThisOrders from "../pages/admin/thisOrder";
import Visualise from "../pages/admin/visualise";
import Brands from "../pages/admin/brands";
import EditeBrand from "../pages/admin/editebrand";
import NewBrand from "../pages/admin/newbrand";
import Categories from "../pages/admin/categories";
import Sales from "../pages/admin/sales";
import NewCategorie from "../pages/admin/newcategorie";
import Visited from "../pages/admin/visited";
import Salesstat from "../pages/admin/salesStat";
import NewFacture from "../pages/admin/newFacture";
import Addtostock from "../pages/admin/addtostock";
import UpdateOrder from "../pages/admin/updateOrder";
import OrderLgs from "../pages/admin/orderLogs";
import UpdateOrderInfo from "../pages/admin/updateOrderInfo";
import Salestrack from "../pages/admin/salestrack";
import Pub from "../pages/admin/pub";
import label from "../pages/admin/label";
import ticket from "../pages/admin/ticket";
import ClientStt from "../pages/admin/clientStt";
//import Login from "../pages/Login";

/*

const routes = [
    {path: "/", name: "home", component: Home},
    {path: "/product/:id", name: "product", component: Product},
    {path: "/account", name: "account", component: Account},
    {path: "/checkout/", name: "checkout", component: Checkout},
    {path: "/cart/", name: "cart", component: Cart},
    {path: "/PayPal", name: "paypal", component: PayPal},
    //{path: "/login", name: "login", component: Login},
];

*/
const Routes = () =>{
    return(
        <Router>
            <Switch>
                <Route exact path="/" name="home" component={Home} />
                <Route exact path="/product/:id" name="product" component={Product} />
                <Route exact path="/account" name="account" component={Account} />
                <Route exact path="/checkout" name="checkout" component={Checkout} />
                <Route exact path="/cart" name="cart" component={Cart} />
                <Route exact path="/paypal" name="paypal" component={PayPal} />
                <Route exact path="/facturation" name="facturation" component={Facturation} />
                <Route exact path="/success" name="Success" component={Success} />
                <Route exact path="/cancelled" name="cancelled" component={Cancelled} />
                <Route exact path="/brand/:br" name="Brand" component={Brand} />
                <Route exact path="/cat/:ct" name="cat" component={Categorie} />
                <Route exact path="/payment" name="Payment" component={Payment} />
                <Route exact path="/profile" name="Profile" component={Profile} />
                
                <Route exact path="/admin" name="adminlog" component={adminLog} />
                <Route exact path="/items" name="items" component={Items} />
                <Route exact path="/additem" name="additem" component={AddItem} />
                <Route exact path="/updateitem/:id" name="updateitem" component={UpdateItem} />
                <Route exact path="/allorders" name="allorders" component={Orders} />
                <Route exact path="/order/:ido" name="order" component={ThisOrders} />
                <Route exact path="/visualise/:ido" name="visualise" component={Visualise} />
                <Route exact path="/info/:ido" name="setInfo" component={UpdateOrderInfo} />
                <Route exact path="/brands" name="brands" component={Brands} />
                <Route exact path="/editebrand/:id" name="editebrand" component={EditeBrand} />
                <Route exact path="/newbrand" name="newbrand" component={NewBrand} />
                <Route exact path="/categories" name="categories" component={Categories} />
                <Route exact path="/newcategorie" name="newcategorie" component={NewCategorie} />
                <Route exact path="/sales" name="sales" component={Sales} />
                <Route exact path="/visited" name="visited" component={Visited} />
                <Route exact path="/salesstat" name="salesstat" component={Salesstat} />
                <Route exact path="/addtostock" name="addtostock" component={Addtostock} />
                <Route exact path="/newFacture" name="newFacture" component={NewFacture} />
                <Route exact path="/updateorder" name="updateorder" component={UpdateOrder} />
                <Route exact path="/orderlogs" name="orderlogs" component={OrderLgs} />
                <Route exact path="/salestrack/:id" name="salestrack" component={Salestrack} />
                <Route exact path="/promotions" name="promotions" component={Promotions} />
                <Route exact path="/pub/:id" name="pub" component={Pub} />
                <Route exact path="/label/" name="label" component={label} />
                <Route exact path="/ticket/:id" name="ticket" component={ticket} />
                <Route exact path="/clientStt/:tel" name="clientstt" component={ClientStt} />
            </Switch>
        </Router>
    )
}


export default Routes;